import { MDXProvider } from '@mdx-js/react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo/seo';

export default function PageSimple({ data }) {
  const { frontmatter, body } = data.mdx;
  const { title } = frontmatter;
  const shortcodes = { Link };

  return (
    <Layout>
      <SEO frontmatter={frontmatter} />
      <div>
        <div className="container py-12  md:pb-24 md:pt-24">
          <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">{title}</h1>
        </div>
      </div>
      <div className="container py-6">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query PageSimpleQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
      body
    }
  }
`;
